<template>
  <div>
    <b-card class="w-100 d-flex rounded-lg">
      <b-row>
          <b-col md="6">
            <b-form
              class="chat-app-form"
              @submit.prevent="sendRole"
            >
              <validation-observer
                class="w-100"
                ref="roleForm"
                #default="{invalid}"
              >
                <p class="h5">Role Name *</p>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  vid="name"
                  rules="required"
                >
                <b-input-group 
                  class="input-group-merge form-send-message mr-1"
                >
                  <b-form-input
                    v-model="form.name"
                    placeholder="Enter new role name"
                  />
                </b-input-group>
                  <small class="text-danger float-left">{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
            </b-form>
          </b-col>
          <b-col md="12">
            <b-button
              variant="primary"
              class="rounded-pill mt-2"
              type="submit"
              @click="sendRole()"
            >
              Send
            </b-button>
          </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BCardText, 
  BButton,
  BLink, 
  BListGroup, 
  BListGroupItem, 
  BCardTitle, 
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BForm
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BCard, 
    BRow, 
    BCol, 
    BCardText, 
    BButton,
    BLink, 
    BListGroup, 
    BListGroupItem, 
    BCardTitle, 
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    required,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form:{
        name: null
      }
    }
  },
  methods:{
    sendRole(){
      this.$refs.roleForm.validate().then(success => {
        if (success) {
          const payload = { query: this.form }
            this.$store.dispatch( 'users/addRole' , payload ).then(res=>{
              this.$router.push({name:'roles'})
            }).catch(error => {
              if (error.response) {
                this.$swal({
                  icon: 'error',
                  title: `<h4>${error.response.data.error}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: '#E84185',
                  allowOutsideClick: true
                });
              }
          });
        }
      });
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
</style>
